
import { defineComponent, onMounted, ref, computed, PropType } from 'vue';
import DocumentHeader from '@/components/BusinessClients/DocumentHeader.vue';
import { BusinessInvoice, BusinessInvoiceCustomer, InvoiceDocumentProduct } from '@/models/Accounting';
import BusinessAccountingForTo from '@/components/BusinessClients/BusinessDocuments/BusinessAccountingForTo.vue';
import BusinessInvoiceCustomersModal from '@/components/BusinessClients/BusinessDocuments/BusinessInvoiceCustomersModal.vue';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';
import InputNumber from 'primevue/inputnumber';
import { generalStore } from '@/store';
import Decimal from 'decimal.js';
import _ from 'lodash';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { InvoiceType } from '@/models/Enums';
import EditInvoiceProductLine from '@/components/Modals/EditInvoiceProductLine.vue';

export default defineComponent({
    props: {
        isNew: {
            type: Boolean,
            default: false
        },
        clientId: {
            type: Number,
            default: null
        },
        invoiceId: {
            type: String,
            default: ''
        },
        editMode: {
            type: Boolean,
            default: false
        },
        type: {
            type: (Object as PropType<InvoiceType>) || null,
            default: null
        },
        orderId: {
            type: String,
            default: null
        }
    },
    components: {
        BusinessInvoiceCustomersModal,
        InputNumber,
        DocumentHeader,
        BusinessAccountingForTo,
        EditInvoiceProductLine
    },
    async setup(props) {
        const { t } = useI18n();
        const isCreditInvoice = ref<boolean>(props.type == InvoiceType.Credit);
        const itemToEdit = ref<InvoiceDocumentProduct>();
        const ShowAddProductLineModal = ref(false);
        const loaded = ref(false);
        const invoiceType = InvoiceType;
        const businessInvoice = ref(new BusinessInvoice(props.type ?? InvoiceType.Debit));
        const vatPercent = generalStore.state.vatPercent;
        const multiplyVAT = generalStore.state.multiplyVAT;
        const multiplyExcludingVAT = generalStore.state.multiplyExcludingVAT;
        const modalBusinessInvoiceCustomers = ref<InstanceType<typeof BusinessInvoiceCustomersModal>>();
        const includeVat = ref(false);
        const customers = ref<BusinessInvoiceCustomer[]>([]);
        const totalAmount = ref(0);
        const localCurrencySymbol = ref('');
        const amountCoverByInvoces = ref(0);
        const perfomaInvoiceTitle = computed(() => generalStore.state.defaultProviderSettings?.defaultProviderSettingsPerfomaInvoiceTitle);
        const totalLeftToCoverInvoiceInLocal = computed(() =>
            businessInvoice.value.customers.reduce((accumulator, object) => {
                return accumulator + object.leftToCoverInvoiceInLocal;
            }, 0)
        );
        const isCredit = computed(() => businessInvoice.value.type == InvoiceType.Credit);

        const invoiceHeader = computed(() => {
            let headerText = '';
            if (props.isNew) {
                if (props.type == InvoiceType.Debit) {
                    headerText = props.clientId ? t('header.new-business-invoice') : t('accounting.newInvoice');
                } else if (props.type == InvoiceType.Perfoma) {
                    headerText = props.clientId
                        ? t('header.new-business-perfoma-invoice', [perfomaInvoiceTitle.value])
                        : t('accounting.newPerfomaInvoice', [perfomaInvoiceTitle.value]);
                } else {
                    headerText = props.clientId ? t('header.new-business-credit-invoice') : t('accounting.newCreditInvoice');
                }
            } else {
                if (businessInvoice.value.type == InvoiceType.Debit) {
                    return props.clientId
                        ? t('documentTypes.BusinessInvoice') + ' #' + businessInvoice.value.documentNumber
                        : t('accounting.invoice') + ' #' + businessInvoice.value.documentNumber;
                } else if (businessInvoice.value.type == InvoiceType.Perfoma) {
                    return props.clientId
                        ? 'Business ' + perfomaInvoiceTitle.value + ' #' + businessInvoice.value.documentNumber
                        : perfomaInvoiceTitle.value + ' #' + businessInvoice.value.documentNumber;
                } else {
                    return props.clientId
                        ? t('documentTypes.BusinessCreditInvoice') + ' #' + businessInvoice.value.documentNumber
                        : t('accounting.creditInvoice') + ' #' + businessInvoice.value.documentNumber;
                }
            }
            return headerText;
        });
        const totalAmountToCoverForCustomers = computed(() => {
            const response = { total: 0, symbol: '₪' };
            if (props.clientId) {
                if (businessInvoice.value.customers.length == 0) {
                    return response;
                }
                response.total = businessInvoice.value.customers.reduce((accumulator, object) => {
                    return accumulator + object.amountToCover * (object.currencyRate || 1);
                }, 0);
            } else {
                if (businessInvoice.value.products.length == 0) {
                    return response;
                }
                response.total = businessInvoice.value.products.reduce((accumulator, object) => {
                    return accumulator + object.total * (object.localCurrencyRate || 1);
                }, 0);
            }

            return response;
        });

        const getTotalAmount = () => {
            return totalAmountToCoverForCustomers.value.total + +(businessInvoice.value.extraAmount ?? 0);
        };

        const getVatAmount = () => {
            const percent = includeVat.value ? multiplyVAT : 0;
            return new Decimal(getTotalAmount()).dividedBy(new Decimal(multiplyExcludingVAT)).mul(percent);
        };

        const productsTotalAmount = computed(() => {
            const amount = new Decimal(getTotalAmount()).minus(getVatAmount());
            return amount.mul(100).dividedBy(100);
        });

        const customersTotalAmount = computed(() => {
            const amount = new Decimal(totalAmountToCoverForCustomers.value.total).minus(getVatAmount());
            return amount.mul(100).dividedBy(100);
        });

        function addModalCustomers() {
            modalBusinessInvoiceCustomers.value?.open();
        }
        function addCustomers(selectedItems: string[]) {
            businessInvoice.value.customers = businessInvoice.value.customers.filter(x => selectedItems.includes(x.id));
            selectedItems.forEach(selectedItem => {
                if (!businessInvoice.value.customers.find(x => x.id == selectedItem)) {
                    const item = customers.value.find(x => x.id == selectedItem);
                    if (item) {
                        item.amountToCover = item.leftToCoverInvoiceInLocal;
                        businessInvoice.value.customers.push(item);
                    }
                }
            });
            businessInvoice.value.customers = _.sortBy(businessInvoice.value.customers, [
                function(o) {
                    return o.orderNumber;
                }
            ]);
        }
        function removeRow(index: number) {
            const idToRemove = businessInvoice.value.customers[index].id;
            modalBusinessInvoiceCustomers.value?.removeSelectedItem(idToRemove);
            businessInvoice.value.customers.splice(index, 1);
        }
        function cancel() {
            if (props.clientId) {
                if (props.editMode) {
                    router.push({ name: 'businessInvoiceView', params: { invoiceId: props.invoiceId, clientId: props.clientId } });
                } else {
                    router.push({ name: 'businessClientView', params: { page: 3, id: props.clientId } });
                }
            } else {
                router.back();
            }
        }
        async function createBusinessInvoice() {
            if (props.clientId) {
                if (!businessInvoice.value.customers.length) {
                    return;
                }
                swal.showLoading();
                const toSavebusinessInvoice: BusinessInvoice = JSON.parse(JSON.stringify(businessInvoice.value));
                toSavebusinessInvoice.customersTotalAmount = productsTotalAmount.value.toString();
                toSavebusinessInvoice.totalAmount = getTotalAmount();
                toSavebusinessInvoice.vatPercent = includeVat.value ? vatPercent : 0;
                toSavebusinessInvoice.vatAmount = getVatAmount().toString();
                if (toSavebusinessInvoice.extraAmount == null) {
                    toSavebusinessInvoice.extraAmount = 0;
                }
                const apiPromise = api.createBusinessInvoice(toSavebusinessInvoice);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                const documentId = apiResult.data?.id;
                if (documentId) {
                    await swal.fire({
                        icon: 'success',
                        title: t('alert.invoiceWasCreated')
                    });
                    router.push({
                        name: 'businessInvoiceView',
                        params: { clientId: props.clientId, invoiceId: documentId }
                    });
                }
            }
        }

        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'info',
                    text: t('alert.pleaseFillAllRequiredFields')
                });
                return;
            } else {
                if (props.clientId) {
                    await createBusinessInvoice();
                } else {
                    if (!businessInvoice.value.products.length) {
                        swal.fire({
                            icon: 'info',
                            text: t('alert.pleaseAddNewLine')
                        });
                        return;
                    }
                    const businessInvoice2: BusinessInvoice = JSON.parse(JSON.stringify(businessInvoice.value));
                    businessInvoice2.orderId = props.orderId;
                    businessInvoice2.productsTotalAmount = productsTotalAmount.value.toString();
                    businessInvoice2.totalAmount = getTotalAmount();
                    if (businessInvoice2.extraAmount == null) {
                        businessInvoice2.extraAmount = 0;
                    }
                    businessInvoice2.vatPercent = vatPercent;
                    businessInvoice2.vatAmount = new Decimal(getVatAmount()).toString();
                    swal.showLoading();
                    businessInvoice2.products.forEach(element => {
                        element.id = '';
                    });
                    businessInvoice2.type = props.type;
                    if (businessInvoice2.documentNumber) {
                        businessInvoice2.documentNumber = businessInvoice2.documentNumber.toString();
                    }
                    const invoiceResponse = await api.createInvoice(businessInvoice2);
                    if (invoiceResponse.errorMessage)
                        swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: invoiceResponse.errorMessage });
                    else if (invoiceResponse.data) {
                        let alertTitle = '';
                        if (businessInvoice2.type == InvoiceType.Credit) {
                            alertTitle = t('alert.creditInvoiceWasCreated');
                        } else {
                            alertTitle = businessInvoice2.id ? t('alert.invoiceWasUpdated') : t('alert.invoiceWasCreated');
                        }
                        swal.fire({
                            icon: 'success',
                            title: alertTitle,
                            confirmButtonText: t('alert.backToOrder')
                        }).then(() => {
                            router.push({ name: 'order', params: { id: props.orderId } });
                        });
                        return;
                    }
                }
            }
        };

        async function shareDocument() {
            router.push({ name: 'sharedocument', params: { documentId: props.invoiceId } });
        }

        async function viewPdfDocument() {
            if (!businessInvoice.value.pdfUrl) {
                swal.showLoading();
                const apiPromise = api.getBusinessInvoicePdf(props.invoiceId);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data || apiResult.data.pdfUrl == null) {
                    swal.fire({
                        icon: 'error',
                        text: t('alert.pdfNotReady')
                    });
                    return;
                }
                businessInvoice.value.pdfUrl = apiResult.data.pdfUrl;
                swal.close();
            }
            window.open(businessInvoice.value.pdfUrl, '_blank');
        }

        function edit() {
            if (props.clientId) {
                router.push({ name: 'businessInvoiceEdit', params: { clientId: props.clientId, invoiceId: props.invoiceId } });
            } else {
                router.push({ name: 'invoiceEdit', params: { orderId: props.orderId, invoiceId: props.invoiceId } });
            }
        }

        function updateRecipientName(recipientName: string) {
            businessInvoice.value.recipientName = recipientName;
        }
        function updateFor(forText: string) {
            businessInvoice.value.for = forText;
        }

        function setItemToEdit(index: number) {
            if (typeof businessInvoice.value.products[index] === 'undefined') {
                return;
            }
            const prod = businessInvoice.value.products[index];
            itemToEdit.value = JSON.parse(JSON.stringify(prod));
            ShowAddProductLineModal.value = !ShowAddProductLineModal.value;
        }
        function closeShowAddProductLineModal() {
            ShowAddProductLineModal.value = !ShowAddProductLineModal.value;
        }
        function makeid(length: number) {
            const result = [];
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            const charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
            }
            return 'fake-' + result.join('');
        }
        function addProductLine(item: InvoiceDocumentProduct) {
            const product = businessInvoice.value.products.find(x => x.id == item.id);
            if (product && product != undefined) {
                product.pricePerUnit = item.pricePerUnit;
                product.quantity = item.quantity;
                product.total = item.total;
                product.discount = item.discount;
                product.name = item.name;
            } else {
                item.currencyId = businessInvoice.value.currencyId;
                item.currencySign = totalAmountToCoverForCustomers.value.symbol;
                item.id = makeid(5);
                item.localCurrencyRate = 1;
                businessInvoice.value.products.push(JSON.parse(JSON.stringify(item)));
            }

            ShowAddProductLineModal.value = false;
        }
        function deleteProductLine(id: string) {
            const index = businessInvoice.value.products.findIndex(x => x.id == id);
            if (index >= 0) {
                businessInvoice.value.products.splice(index, 1);
            }
            ShowAddProductLineModal.value = !ShowAddProductLineModal.value;
        }

        function addNewLine() {
            itemToEdit.value = new InvoiceDocumentProduct();
            itemToEdit.value.currencyId = businessInvoice.value.currencyId;
            ShowAddProductLineModal.value = !ShowAddProductLineModal.value;
        }

        async function onMountedAction() {
            swal.showLoading();
            if (props.isNew) {
                const apiPromise = api.getBusinessClientInfoForInvoice({
                    clientId: props.clientId,
                    type: props.type,
                    orderId: props.orderId
                });
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data) {
                    swal.close();
                    return;
                }
                if (props.clientId) {
                    customers.value = apiResult.data.rows;
                } else if (props.orderId) {
                    businessInvoice.value.currencyId = apiResult.data.currencyId;
                    businessInvoice.value.for = `${t('new-invoice.order-number')} ${apiResult.data.orderNumber}`;
                    if (props.type != InvoiceType.Credit) {
                        businessInvoice.value.products = apiResult.data.products;
                        businessInvoice.value.products.forEach(element => {
                            element.id = makeid(5);
                        });
                    }
                }
                businessInvoice.value.recipientName = apiResult.data.recipientName;
                businessInvoice.value.businessClientId = apiResult.data.businessClientId;
                businessInvoice.value.createdDate = apiResult.data.todayData;
                includeVat.value = apiResult.data.includeVat;
                totalAmount.value = apiResult.data.totalAmount;
                localCurrencySymbol.value = apiResult.data.localCurrencySymbol;
                amountCoverByInvoces.value = apiResult.data.amountCoverByInvoces;
            } else {
                const apiPromise = api.getInvoiceDocument(props.invoiceId, props.orderId, props.clientId);
                await q.delay(400);
                const apiResult = await apiPromise;
                if (apiResult.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage
                    });
                    return;
                }
                if (!apiResult.data) {
                    swal.close();
                    return;
                }
                businessInvoice.value = apiResult.data;
                includeVat.value = apiResult.data.includeVat;
            }
            loaded.value = true;
            swal.close();
        }
        onMounted(onMountedAction);
        return {
            addCustomers,
            businessInvoice,
            modalBusinessInvoiceCustomers,
            addModalCustomers,
            removeRow,
            vatPercent,
            includeVat,
            loaded,
            totalAmountToCoverForCustomers,
            getVatAmount,
            customersTotalAmount,
            productsTotalAmount,
            getTotalAmount,
            customers,
            totalAmount,
            localCurrencySymbol,
            amountCoverByInvoces,
            cancel,
            submitForm,
            totalLeftToCoverInvoiceInLocal,
            viewPdfDocument,
            shareDocument,
            edit,
            invoiceType,
            invoiceHeader,
            updateRecipientName,
            updateFor,
            setItemToEdit,
            ShowAddProductLineModal,
            itemToEdit,
            closeShowAddProductLineModal,
            addProductLine,
            deleteProductLine,
            addNewLine,
            isCreditInvoice,
            isCredit
        };
    }
});
